﻿/**
 *  @ngdoc controller
 *  @name Drilldot Directive
 *  @description Drill-Dot Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('rxInvoice', function ($timeout) {
        return {
            controller: 'rxInvoiceController',
            restrict: 'E',
            scope: {
                invoiceDetails: '=',
                invFlag:'='
            },
            templateUrl: 'app/directive/rxinvoice/rxinvoicedir.html'
        }
    }).controller('rxInvoiceController', function ($scope, eyeResource) {
        $scope.shipToName = ($scope.invoiceDetails.orderShipping.shipToName != null && $scope.invoiceDetails.orderShipping.shipToName != "") ? ($scope.invoiceDetails.orderShipping.shipToName) : ($scope.invoiceDetails.orderShipping.isPatient == true ? ($scope.invoiceDetails.patientName) : ($scope.invoiceDetails.practiceName));
        $scope.getDotColor = function (dotNo, colorType, fenestrationValue) {
            if (dotNo != null && colorType != null) {
                var dot = dotNo == 0 ? "1 Dot /" : dotNo == 1 ? "2 Dots /" : null;
                var type = colorType == 0 ? "Black" : colorType == 1 ? "White" : "Clear";
                return (dot + type + (fenestrationValue ? (" (" + fenestrationValue + "F)") : ""));// added fenestration condition by shwetha
            }
        }
    });

}(window.angular));